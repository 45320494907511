// ----------------------------------------------------------------------

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Homem', value: 'Men' },
  { label: 'Mulher', value: 'Women' },
  { label: 'Unissexo', value: 'Unissex' },
];

export const PRODUCT_CATEGORY_OPTIONS = ['Shose', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'transparente', label: 'Transparente' },
  { value: 'multicor', label: 'Multicor' },
  { value: 'preto', label: 'Preto' },
  { value: 'branco', label: 'Branco' },
  { value: 'cinza', label: 'Cinza' },
  { value: 'vermelho', label: 'Vermelho' },
  { value: 'azul', label: 'Azul' },
  { value: 'verde', label: 'Verde' },
  { value: 'amarelo', label: 'Amarelo' },
  { value: 'roxo', label: 'Roxo' },
  { value: 'laranja', label: 'Laranja' },
  { value: 'prata', label: 'Prata' },
  { value: 'dourado', label: 'Dourado' },
  { value: 'cinza-mate', label: 'Cinza-Mate' },
  { value: 'azul-escuro', label: 'Azul-Escuro' },
  { value: 'azul-meia-noite', label: 'Azul Meia-noite' },
];


export const PRODUCT_SIZE_OPTIONS = [
  { value: 'xs-xxxl', label: 'De XS-XXXL' },
  { value: 'unique', label: 'Único' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  {
    classify: ['Acessórios Condutor', 'Acessórios Mota', 'Merchandising', 'Boutique'],
  },
];

export const PRODUCT_CHECKOUT_STEPS = ['Cart', 'Billing & address', 'Payment'];
