import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { useRouter, useSearchParams } from 'src/routes/hooks';

import  { endpoints } from 'src/utils/axios';
import axios, { endpointsLocal } from 'src/utils/axiosLocal';

import { PATH_AFTER_LOGIN } from 'src/config-global';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
      authenticated: !!action.payload.user, // Set authenticated to true if there is a user
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      authenticated: true, // Set authenticated to true after successful login
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
      authenticated: true, // Set authenticated to true after successful registration
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      authenticated: false, // Set authenticated to false after logout
    };
  }
  return state;
};


// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpointsLocal.auth.me);

        const { user } = response.data;

        if (user) {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...user,
                accessToken,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  
  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async () => {
    try {
      // Simulate successful login without sending credentials to the server
      const accessToken = 'your_dummy_access_token';
      const user = {
        id: 1,
        username: 'john_doe',
        email: 'john.doe@example.com',
        // ... other user data
      };
  
      // Dispatch the login action
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
  
      // Route to the specified path or default PATH_AFTER_LOGIN
      router.push(returnTo || PATH_AFTER_LOGIN);
    } catch (error) {
      console.error('Error during login:', error);
    }
  }, [dispatch, router, returnTo]);
  

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
