import React, { useState, useEffect } from 'react';
import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import { Box, Avatar, Divider, MenuItem, Typography, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { useRouter } from 'src/routes/hooks';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useAuthContext } from 'src/auth/hooks';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import axios from 'axios'; // Ensure axios import is first
import { clearDB } from '../../sections/auth/hashConnect'; // Import after axios

export default function AccountPopover() {
  const router = useRouter();
  const { user } = useMockedUser();
  const { logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const popover = usePopover();
  const [isAutoClaim, setIsAutoClaim] = useState(true); // Ensure the checkbox is checked by default

  useEffect(() => {
    // Fetch the current auto-claim status when the component mounts
    const fetchAutoClaimStatus = async () => {
      const accountId = localStorage.getItem('account_id');
      const accessToken = localStorage.getItem('access_token');
      
      if (!accountId) {
        console.error('No account ID found in local storage');
        return;
      }

      try {
        const response = await axios.get('https://www.uturenegades.com/api/getAutoClaimStatus', {
          params: { accountId },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setIsAutoClaim(response.data.autoClaim);
      } catch (error) {
        console.error('Error fetching auto-claim status:', error);
        enqueueSnackbar('Error fetching auto-claim status', { variant: 'error' });
      }
    };
    fetchAutoClaimStatus();
  }, [enqueueSnackbar]);
  

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setIsAutoClaim(isChecked);

    // Update the auto-claim status in the backend
    const accessToken = localStorage.getItem('access_token');

    try {
      await axios.put('https://www.uturenegades.com/api/changeAutomaticClaim', {
        accountId: localStorage.getItem('account_id'),
        automaticClaimRewards: isChecked,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      enqueueSnackbar('Auto-claim status updated', { variant: 'success' });
    } catch (error) {
      console.error('Error updating auto-claim status:', error);
      enqueueSnackbar('Error updating auto-claim status', { variant: 'error' });
    }
  };

  const handleLogout = async () => {
    try {
      await clearDB();
      localStorage.clear();
      sessionStorage.clear();
      await logout();
      router.replace('/');
      popover.onClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const accountId = localStorage.getItem('account_id');

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src="/assets/images/avatar2.jpg"
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.displayName?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {accountId}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAutoClaim}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#fff',
                  cursor: 'pointer',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  transition: 'background-color 0.3s, color 0.3s',
                }}
              >
                {isAutoClaim ? 'Auto claim' : 'Manual claim'}
              </span>
            }
          />
        </Box>

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
