import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback, createContext } from 'react';

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const setAdmin = useCallback((status) => {
    setIsAdmin(status);
  }, []); // Empty dependency array since setIsAdmin is a state setter function

  const contextValue = useMemo(() => ({ isAdmin, setAdmin }), [isAdmin, setAdmin]);

  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
};

AdminProvider.propTypes = {
  children: PropTypes.node, // Add PropTypes validation for children prop
};

export { AdminContext, AdminProvider };
