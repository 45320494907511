// useAdminContext.js
import { useContext } from 'react';

import { AdminContext } from './AdminContext';

export const useAdminContext = () => {
  const adminContext = useContext(AdminContext);
  if (!adminContext) {
    throw new Error('useAdminContext must be used within an AdminContextProvider');
  }
  return adminContext;
};
