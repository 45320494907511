import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';

import FormProvider from 'src/components/hook-form';

import { connectWalletHashPack } from '../hashConnect';
import { useAdminContext } from '../../../context/useAdminContext'; // Import the admin context hook

export default function JwtLoginView() {
  const [errorMsg, setErrorMsg] = useState('');
  const [isConnectingWallet, setIsConnectingWallet] = useState(false); 
  const { login, isAuthenticated } = useAuthContext();
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const { formState } = useForm();
  const { setAdmin } = useAdminContext(); // Use the admin context hook


  const fetchData = async () => {
    const accountId = localStorage.getItem('account_id');
    if (accountId) {
      try {
        const response = await axios.post('https://www.uturenegades.com/api/checkid', { account_id: accountId });
        if (response.data.allowed) {
          login(accountId);
          if (returnTo) {
            router.push(returnTo);  
          } else {
            router.push(PATH_AFTER_LOGIN);
          }
        } else {
          setErrorMsg('This account ID is not allowed or has no NFTs from the specified token.');
        }
      } catch (error) {
        setErrorMsg('Error connecting wallet. Please try again.');
        console.error('Error connecting wallet:', error);
      }
    }
  };

  const onSubmit = async () => {
    setIsConnectingWallet(true); 
    try {
      const { account_id, access_token, checkid_response } = await connectWalletHashPack(setAdmin);
      if (checkid_response && checkid_response.allowed) {
        setErrorMsg('');
        const user = {
          id: 1,
          username: 'john_doe',
          email: 'john.doe@example.com',
        };
        login(access_token, user);
        router.push(PATH_AFTER_LOGIN);
      } else {
        setErrorMsg('This account ID is not allowed or has no NFTs from the specified token.');
      }
    } catch (error) {
      setErrorMsg('Error initiating wallet connection. Please try again.');
      console.error('Error handling form submission:', error);
    } finally {
      setIsConnectingWallet(false); 
    }
  };
  
  
  const renderForm = (
    <Stack spacing={2.5}>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        variant="contained"
        onClick={onSubmit}
        loading={isConnectingWallet}
        sx={{
          backgroundColor: 'red', 
          color: 'white', 
          '& .MuiCircularProgress-svg': { 
            color: 'red', 
          },
        }}
      >
        Connect Wallet
      </LoadingButton>

        <LoadingButton
          style={{
            backgroundColor: 'transparent',
            color: 'red',
            marginTop: '25px',
            fontSize : '1.05rem',
          }}
        >
          TO JOIN OUR COMMUNITY YOU MUST HOLD AT LEAST 1 UTU RENEGADES
        </LoadingButton>

        <LoadingButton
          style={{
            backgroundColor: 'transparent',
            color: 'white',
            fontSize : '1.49rem',
          }}
        >
          BUY IT AT SENTX OR KABILA
        </LoadingButton>

    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)', gap: '40px', alignItems: 'center' }}>
      <Link href="https://sentx.io/nft-marketplace/utu-0.0.4862817" target="_blank" rel="noopener noreferrer">
        <img
          src="/assets/sentx.png"
          alt="Buy at Sentx"
          width={150}
          height={55}
          style={{ marginTop: '5px', cursor: 'pointer' }}
        />
      </Link>
      <Link href="https://market.kabila.app/en/collections/4862817" target="_blank" rel="noopener noreferrer">
        <img
          src="/assets/UTU.png"
          alt="Buy at Kabila"
          width={150}
          height={55}
          style={{ marginTop: '5px', cursor: 'pointer' }}
        />
      </Link>
    </div>
    
    </Stack>
  );

  return (
    <>
      <Box
        component="img"
        alt="logo"
        src="/logo/131.png"
        sx={{
          width: '100%',
          height: '45%',
          display: 'block',
          mx: 'auto',
          textAlign: 'center',
        }}
      />
      {!!errorMsg && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMsg}
        </Alert>
      )}
      <FormProvider>{renderForm}</FormProvider>
    </>
  );
}
