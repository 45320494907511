import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { UploadIllustration } from 'src/assets/illustrations';

import Iconify from '../iconify';
import MultiFilePreview from './preview-multi-file';
import SingleFilePreview from './preview-single-file';

// ----------------------------------------------------------------------

// ...

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  file,
  onDelete,
  files,
  thumbnail,
  maxFiles,
  onRemove,
  onRemoveAll,
  sx,
  ...other
}) {

  // Ensure that the number of files doesn't exceed maxFiles
  const limitedFiles = files ? files.slice(0, maxFiles) : [];
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled: limitedFiles.length >= maxFiles,
    maxFiles: multiple ? maxFiles : 1,
    ...other,
  });


  const hasFile = !!file && !multiple;
  const hasFiles = !!limitedFiles && multiple && !!limitedFiles.length;
  const hasError = isDragReject || !!error;

  const renderPlaceholder = (
    <Stack spacing={3} alignItems="center" justifyContent="center" flexWrap="wrap">
      <UploadIllustration sx={{ width: 1, maxWidth: 200 }} />
      <Stack spacing={1} sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Arraste ou Selecione o Ficheiro</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Arraste os ficheiros aqui ou clique
          <Box
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
              ...(limitedFiles.length >= maxFiles && {
                // Estilos adicionais para indicar visualmente que o componente está desativado
                opacity: 0.5,
                pointerEvents: 'none',
              }),
            }}
          >
            explorar
          </Box>
          através do seu dispositivo
        </Typography>
      </Stack>
    </Stack>
  );
  

  const renderSinglePreview = (
    <SingleFilePreview imgUrl={typeof file === 'string' ? file : file?.preview} />
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: (theme) => alpha(theme.palette.common.white, 0.8),
        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
        },
      }}
    >
      <Iconify icon="mingcute:close-line" width={18} />
    </IconButton>
  );

  const renderMultiPreview = hasFiles && (
    <Box sx={{ my: 3 }}>
        <MultiFilePreview files={limitedFiles} thumbnail={thumbnail} onRemove={onRemove} />
      </Box>
  );
  

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }} />
  );
}

Upload.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  files: PropTypes.array,
  helperText: PropTypes.object,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  sx: PropTypes.object,
  thumbnail: PropTypes.bool,
  maxFiles: PropTypes.number, // Add maxFiles prop to the propTypes
};
